import React from "react";
import "./storeButton.css";

const StoreButton = () => {
  const storeLink = "https://store.samek.sa/products";

  return (
    <a
      href={storeLink}
      target="_blank"
      rel="noopener noreferrer"
      className="Storefloating-button"
    >
      <span className="icon">
        <svg
          class="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          // viewBox="0 0 50 50"
          width="30px"
          height="30px"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M14 7h-4v3a1 1 0 0 1-2 0V7H6a1 1 0 0 0-.997.923l-.917 11.924A2 2 0 0 0 6.08 22h11.84a2 2 0 0 0 1.994-2.153l-.917-11.924A1 1 0 0 0 18 7h-2v3a1 1 0 1 1-2 0V7Zm-2-3a2 2 0 0 0-2 2v1H8V6a4 4 0 0 1 8 0v1h-2V6a2 2 0 0 0-2-2Z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </a>
  );
};
export default StoreButton;
