import React from "react";
import "./services.css";
import ServicesCard from "./servicesCard/ServicesCard";
const Services = ({ data, lang, servicesTitle, servicesDesc }) => {
  return (
    <>
      <div className="d-block d-md-none">
        <div id="services" className="container pt-5">
          <div className="text-center d-flex flex-column align-items-center gap-1">
            <p className={`m-0 p-0 text-uppercase title`}>{servicesTitle}</p>
            <p className={`m-0 p-0 fw-bold desc`}>{servicesDesc}</p>
          </div>

          <div className={`ServiceContainer`}>
            {data.map((item, index) => (
              <ServicesCard
                key={index}
                data={item}
                lang={lang}
                Reversed={index % 2 === 0 ? false : true}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div id="services" className="pt-5">
          <div className="text-center d-flex flex-column align-items-center gap-1">
            <p className={`m-0 p-0 text-uppercase title`}>{servicesTitle}</p>
            <p className={`m-0 p-0 fw-bold desc`}>{servicesDesc}</p>
          </div>
          <div className="container ServiceContainer">
            {data.map((item, index) => (
              <ServicesCard
                key={index}
                data={item}
                lang={lang}
                Reversed={index % 2 === 0 ? false : true}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
