import React from "react";
import { Link } from "react-router-dom";
import "./ServicesCard.css";
const ServicesCard = ({ data, Reversed, lang }) => {
  return (
    <div
      className={`serviceCard align-items-center ${
        Reversed ? "cardRow" : "cardRowReverse"
      }`}
    >
      <Link to={`/service/${data.id}`} className="imgLink">
        <img src={`${data.photo}`} alt="..."></img>
      </Link>

      <div className="card-body">
        <h5 className="card-title">{data.title}</h5>
        <p className="card-text">{data.description}</p>
        <Link to={`/service/${data.id}`} className="btn btn-primary mt-3">
          {lang === "en" ? "read more" : "مشاهدة المزيد"}
        </Link>
      </div>
    </div>
  );
};

export default ServicesCard;

/* <div
      className={`${
        Reversed ? "row flex-row-reverse" : "row"
      } align-items-center`}
    >
      <div className={`col-12 col-md-6 mb-3 p-0 mb-md-0 ${style.imgContainer}`}>
        <div
          style={{
            position: "relative",
            backgroundImage: `url(${data.photo})`,
            backgroundSize: "cover",
            // backgroundAttachment: "fixed",
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div className={`col-12 col-md-6 mb-3 mb-md-0 pb-3`}>
        <h3 className="mx-0 mt-0 mb-3 px-3 px-md-4">{data.title}</h3>
        <p className="m-0 px-3 px-md-4">{data.description}</p>
      </div>
    </div> */
