import React from "react";

import { useQuery } from "react-query";
import { request } from "../components/utils/axios.js";
import Spinner from "../components/utils/spinner/Spinner.jsx";
import Banner from "../components/banner/banner.jsx";
import { useParams } from "react-router-dom";

const Service = ({ lang }) => {
  const { id } = useParams(); // Get the id parameter from the route

  const fetchData = async (id) => {
    return await request({ url: `/service/${id}` });
  };
  const { data, isLoading } = useQuery(["service", id], () => fetchData(id));
  const removeLastParagraph = (htmlString) => {
    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    // Find all <p> elements
    const paragraphs = tempElement.querySelectorAll("p");

    // Remove the last <p> element
    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      lastParagraph.parentNode.removeChild(lastParagraph);
    }

    // Return the modified HTML
    return tempElement.innerHTML;
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Banner
            title={`${data?.data?.data?.title}`}
            background={data?.data?.data?.photo}
          />

          <div
            className="container py-5"
            dangerouslySetInnerHTML={{
              __html: removeLastParagraph(data?.data?.data?.full_desc),
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Service;
