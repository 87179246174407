import React from "react";
import "./productCard.css";
const ProductCard = ({ data, lang }) => {
  return (
    <div className={`card align-items-center`}>
      <img src={`${data.photo}`} alt="..."></img>
      <div className="card-body">
        <h5 className="card-title">{data.name}</h5>
        <p className="card-text">{truncateDescription(data.desc)}</p>
        <a href={`${data.link}`} className="btn btn-primary mt-3">
          {lang === "en" ? "read more" : "اشتر الان"}
        </a>
      </div>
    </div>
  );
};
// Function to truncate description text to three lines
const truncateDescription = (text) => {
  const maxLength = 2; // Maximum number of lines
  const lines = text.split("\n").slice(0, maxLength);
  return lines.join("\n");
};
export default ProductCard;
