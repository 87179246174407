import React from "react";
import "./Banner.css"; // Assuming you have a CSS file for styling
const Banner = ({ title, background }) => {
  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url('${background}')`,
      }}
    >
      <div className="overlay"></div>
      <div className="banner-content">
        <h1 className="banner-title">{title}</h1>
      </div>
    </div>
  );
};

export default Banner;
