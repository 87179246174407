import React from "react";
import "./partners.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
const Partners = ({ data, lang }) => {
  const swiperOptions = {
    loop: true,
    // centeredSlides: true,
    spaceBetween: 0,
    // arrows: true,
    autoplay: {
      delay: 1000,
    },
    // pagination: {
    //   clickable: true,
    // },
    breakpoints: {
      500: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  };
  return (
    <>
      <div className={` p-0 mainDiv`}></div>
      <div className={`m-0  pt-0 pb-5 mainDiv`}>
        <div className="container">
          <p className={`m-0 px-0 pt-2 pb-0 text-center title`}>
            {lang === "en" ? "our partners" : "شركاء النجاح"}
          </p>
          <Swiper
            modules={[Autoplay, Navigation]}
            {...swiperOptions}
            className="mySwiper mt-5"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  alt="partner/img"
                  loading="lazy"
                  src={item.photo}
                  className={`img`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Partners;
