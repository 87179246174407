import React from "react";
import About from "../components/about/About.jsx";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios.js";
import Spinner from "../components/utils/spinner/Spinner.jsx";
import Banner from "../components/banner/banner.jsx";
import background from "../assets/pipes.jpg";
import "./AboutUs.css";

const AboutUs = ({ lang }) => {
  const fetchData = async () => {
    return await request({ url: "/settings" });
  };
  const { data, isLoading } = useQuery("home", fetchData);
  const removeLastParagraph = (htmlString) => {
    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    // Find all <p> elements
    const paragraphs = tempElement.querySelectorAll("p");

    // Remove the last <p> element
    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      lastParagraph.parentNode.removeChild(lastParagraph);
    }

    // Return the modified HTML
    return tempElement.innerHTML;
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Banner
            title={lang === "en" ? "About Us" : "من نحن"}
            background={background}
          />
          <About lang={lang} data={data.data.data.aboutUs} button={false} />
          <div className="aboutContainer container  d-flex align-items-center">
            <div
              dangerouslySetInnerHTML={{
                __html: removeLastParagraph(
                  data.data.data.aboutUs.aboutUs_fulldes
                ),
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUs;
