import React from "react";
import Hero from "../components/hero/Hero.jsx";
import About from "../components/about/About.jsx";
import Services from "../components/services/Services.jsx";
import Partners from "../components/partners/Partners.jsx";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios.js";
import Spinner from "../components/utils/spinner/Spinner.jsx";
import "./Home.css";
const Home = ({ lang, servicesTitle, servicesDesc }) => {
  const fetchData = async () => {
    return await request({ url: "/settings" });
  };
  const { data, isLoading } = useQuery("home", fetchData);
  const fetchServices = async () => {
    return await request({ url: "/services" });
  };
  const { data: data2, isLoading: loadingServices } = useQuery(
    "services",
    fetchServices
  );
  const fetchPartners = async () => {
    return await request({ url: "/partners" });
  };
  const { data: data3, isLoading: loadingPartners } = useQuery(
    "partners",
    fetchPartners,
    {
      cacheTime: 1800000,
      staleTime: 1800000,
    }
  );
  return (
    <>
      {isLoading || loadingServices || loadingPartners ? (
        <Spinner />
      ) : (
        <div>
          <Hero
            lang={lang}
            data={data.data.data.statistics}
            slider={data.data.data.slider}
          />
          <About lang={lang} data={data.data.data.aboutUs} button={true} />
          <Services
            servicesDesc={servicesDesc}
            servicesTitle={servicesTitle}
            lang={lang}
            data={data2?.data?.data}
          />
          <Partners lang={lang} data={data3.data.data} />
        </div>
      )}
    </>
  );
};

export default Home;
