import React from "react";
import "./about.css";
const About = ({ data, lang, button }) => {
  // console.log(button);
  return (
    <div
      id="about"
      className="aboutContainer container mt-5 pt-lg-5 d-flex align-items-center"
    >
      <div className="AboutTextContainer p-0 m-0 col-6 mb-3 mb-md-0">
        <h2 className={`title mb-4 mx-0 mt-0 p-0`}>{data.aboutUs_title}</h2>
        <p className={`mx-0 mt-0 mb-5 aboutdesc`}>{data.aboutUs_des}</p>

        {button === true ? (
          <a className="text-white btn" href={"/about"}>
            {lang === "en" ? "read more" : "مشاهدة المزيد"}
          </a>
        ) : (
          ""
        )}
      </div>
      <div className="">
        <img
          loading="lazy"
          className="aboutImg"
          src={data.aboutUs_img}
          alt="about/img"
        />
      </div>
    </div>
  );
};

export default About;
