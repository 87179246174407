import React, { useState, useEffect } from "react";
import style from "./hero.module.css";
import { useInView } from "react-intersection-observer";
import Carousel from "react-bootstrap/Carousel";
const Hero = ({ slider, data, lang }) => {
  const [count, setCount] = useState(data.map(() => 0));
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when section becomes visible
  });
  useEffect(() => {
    const incrementAutomatically = () => {
      if (inView) {
        setCount((prevCounters) =>
          prevCounters.map((count, index) =>
            count < data[index].number ? count + 1 : count
          )
        );
      }
    };

    const interval = setInterval(incrementAutomatically, 50);
    return () => {
      clearInterval(interval);
    };
  }, [inView, data]);
  return (
    <div className={style.mainContainer} id="home">
      {/** slider */}
      <Carousel className="mySlider">
        {slider.map((item, index) => (
          <Carousel.Item key={index}>
            <div className="image-overlay-container">
              <img
                className={`d-block ${style.mainImg} w-100 zoom-in-image`}
                src={item.image}
                alt={item.title}
              />
              <div className="image-overlay">
                <div className="d-flex flex-column align-items-center fs-1">
                  <p className="text-white fw-bold m-0 p-0">{item.title}</p>
                  <div>
                    {item.textTwo && (
                      <p className="text-white  fw-bold m-0 p-0 d-inline-block">
                        {item.textTwo}
                      </p>
                    )}
                    {item.greenText && (
                      <p
                        className={`fw-bold my-0 mx-1 p-0 d-inline-block ${style.life}`}
                      >
                        {item.greenText}
                      </p>
                    )}
                    {item.textThree && (
                      <p className="text-white  fw-bold m-0 p-0 d-inline-block">
                        {item.textThree}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div
        id="stat"
        className={`w-100 container ${style.statContainer} d-flex  flex-wrap gap-5 justify-content-center`}
        ref={ref}
      >
        {data.map((item, index) => (
          <div className={`${style.mainCard}`} key={index}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div>
                <p
                  className={`fw-bold d-inline-block m-0 p-0 green ${style.num}`}
                >
                  {count[index]}
                </p>
                {/**
                   *  <p className={`fw-bold d-inline-block m-0 p-0  ${style.num}`}>
                  {lang === "en" ? "k" : "الف"}
                </p>
                   */}
              </div>
              <p className="mx-0 mt-2 mb-0 p-0">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
