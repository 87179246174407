import React from "react";

import { useQuery } from "react-query";
import { request } from "../components/utils/axios.js";
import Spinner from "../components/utils/spinner/Spinner.jsx";

import ProductCard from "../components/productCard/productCard.jsx";
import Banner from "../components/banner/banner.jsx";
import background from "../assets/pipes.jpg";

const Products = ({ lang }) => {
  const fetchData = async () => {
    return await request({ url: "/products" });
  };
  const { data, isLoading } = useQuery("products", fetchData);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Banner
            title={lang === "en" ? "Products" : "المنتجات"}
            background={background}
          />

          <div className="container pt-5">
            <div className="row row-gap-4">
              {data?.data?.data.map((item, index) => (
                <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                  <ProductCard key={index} data={item} lang={lang} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
